import { View, Text, FlatList, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import ExtraProductCard from './ExtraProductCard'

const ExtraProduct = () => {
    const [products,setProducts]=useState([
        {
           id:61
           , name_ar: "مرق بطاطا"
           , name_en: "Potato Juice"
           , image1: ""
           , description_ar: "مرق بطاطا"
           , description_en: "Potato Juice"
           , discount: 0
           , amount: 0.25
        },
        {
           id:64
           , name_ar: "دقوس"
           , name_en: "Dukus"
           , image1: ""
           , description_ar: "دقوس"
           , description_en: "Dukus"
           , discount: 0
           , amount: 0.25
        },
        {
           id:63
           , name_ar: "روب و خيار"
           , name_en: "Yogurt and cucumber"
           , image1: ""
           , description_ar: "روب و خيار"
           , description_en: "Yogurt and cucumber"
           , discount: 0
           , amount: 0.5
        }
    ])
 
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>اضافة من اختيارك</Text>
        <Text style={styles.subtitle}>اختر المنتجات الإضافية التي تريدها</Text>
      </View>
      <FlatList
        data={products}
        renderItem={({item}) => (
          <ExtraProductCard item={item}/>
        )}
        keyExtractor={item => item.id.toString()}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.listContainer}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#002F25',
    marginBottom: 8,
    fontFamily: 'Almarai-Bold',
  },
  subtitle: {
    fontSize: 14,
    color: '#666',
    fontFamily: 'Almarai',
  },
  listContainer: {
    paddingBottom: 16,
  },
});

export default ExtraProduct;