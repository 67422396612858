import { View, Text, FlatList, StyleSheet } from 'react-native'
import React, { useState } from 'react'
import ExtraProductCardRadio from './ExtraProductCardRadio'

const ExtraProductRadio = ({ setExtraMeal }) => {
    const [selectedId, setSelectedId] = useState(null);
    const [products] = useState([
        {
           id:61
           , name_ar: "مرق بطاطا"
           , name_en: "Potato Juice"
           , image1: ""
           , description_ar: "مرق بطاطا"
           , description_en: "Potato Juice"
           , discount: 0
           , amount: 0
        },
        {
           id:64
           , name_ar: "دقوس"
           , name_en: "Dukus"
           , image1: ""
           , description_ar: "دقوس"
           , description_en: "Dukus"
           , discount: 0
           , amount: 0
        }
    ]);

    const handleSelect = (item) => {
        setSelectedId(item.id);
        setExtraMeal(item);
    };
 
    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>اختر 1 </Text>
                <Text style={styles.subtitle}>اختر واحدة من القائمة</Text>
            </View>
            <FlatList
                data={products}
                renderItem={({item}) => (
                    <ExtraProductCardRadio
                        item={item}
                        isSelected={selectedId === item.id}
                        onSelect={handleSelect}
                    />
                )}
                keyExtractor={item => item.id.toString()}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.listContainer}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F4F4F4',
        padding: 16,
    },
    header: {
        marginBottom: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#002F25',
        marginBottom: 8,
        fontFamily: 'Almarai-Bold',
    },
    subtitle: {
        fontSize: 14,
        color: '#666',
        fontFamily: 'Almarai',
    },
    listContainer: {
        paddingBottom: 16,
    },
});

export default ExtraProductRadio;