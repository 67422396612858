import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native'
import React from 'react'
import { useDispatch } from 'react-redux';

const ExtraProductCard = ({item}) => {
  const dispatch = useDispatch();
  return (
    <View style={styles.card}>
      <View style={styles.contentContainer}>
        <Text style={styles.name}>{item.name_ar}</Text>
        <Text style={styles.description}>{item.description_ar}</Text>
        <View style={styles.priceContainer}>
          {item.discount > 0 && (
            <Text style={styles.originalPrice}>
              {Number(item.amount)} د.ك
            </Text>
          )}
          <Text style={styles.price}>
            {Number(item.amount - item.discount)} د.ك
          </Text>
        </View>
        <TouchableOpacity
          style={styles.addButton}
          onPress={() => {
            dispatch({
              type: "addToCart",
              payload: {
                id: item.id,
                item_id: item.id,
                name_ar: item.name_ar,
                image1: item['image1'],
                amount: Number(item.amount) - Number(item.discount),
                totalamount: (Number(item.amount) - Number(item.discount)),
                at1value: "0",
                at1_value: "0",
                at2_value: "0",
                at3_value: "0",
                at4_value: "0",
                at5_value: "0",
                at6_value: "0",
                at7_value: "0",
                at8_value: "0",
                at1_price: "0",
                at2_price: "0",
                at3_price: "0",
                at4_price: "0",
                at5_price: "0",
                at6_price: "0",
                at7_price: "0",
                at8_price: "0",
                qty: 1
              }
            })
            alert("تم إضافة المنتج إلى السلة")
          }}
        >
          <Text style={styles.addButtonText}>+</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    borderRadius: 12,
    margin: 8,
    padding: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  contentContainer: {
    flex: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#002F25',
    marginBottom: 4,
    fontFamily: 'Almarai-Bold',
  },
  description: {
    fontSize: 14,
    color: '#666',
    marginBottom: 8,
    fontFamily: 'Almarai',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  originalPrice: {
    fontSize: 14,
    color: '#999',
    textDecorationLine: 'line-through',
    marginRight: 8,
    fontFamily: 'Almarai',
  },
  price: {
    fontSize: 16,
    color: '#3F9952',
    fontWeight: 'bold',
    fontFamily: 'Almarai-Bold',
  },
  addButton: {
    width: 32,
    height: 32,
    backgroundColor: '#002F25',
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-end',
  },
  addButtonText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Almarai-Bold',
    lineHeight: 20,
  },
});

export default ExtraProductCard;